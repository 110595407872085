.react-datepicker__day--selected,
.react-datepicker__month--selected,
.react-datepicker__year--selected {
  color:#5078E1 !important;
  background-color: #F2F8FF !important;
  border: 1px solid #1f74ea;
  border-radius: 23px !important;
  /* padding: 4px !important; */
  font-size: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month--keyboard-selected,
.react-datepicker__year--keyboard-selected {
  color:#5078E1 !important;
  background-color: #F2F8FF !important;
  border: 1px solid #1f74ea;
  border-radius: 23px !important;
    /* padding: 4px !important; */
      font-size: 0.75rem;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';



}

.react-datepicker__year-text{
 margin: 12px 6px !important;
      font-size: 0.75rem !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';
}
.react-datepicker__month-text{
      font-size: 0.75rem !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';
}
.react-datepicker__day-text{
      font-size: 0.75rem !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular' !important;
}
.react-datepicker__year-text--selected{
   color:#5078E1 !important;
  background-color: #F2F8FF !important;
  border: 1px solid #1f74ea;
  border-radius: 23px !important;
    /* padding: 4px !important; */
      font-size: 0.75rem;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';
}
.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  border-radius: 16px !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day {
  border-radius: 16px;
   font-size: 0.75rem !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';
}
.react-datepicker__month {
  border-radius: 16px;
   font-size: 0.75rem !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Regular';
}
.react-datepicker__year {
  border-radius: 16px;
   font-size: 0.75rem !important;
}

.react-datepicker__navigation--next {
  top: 16px !important;
  right: 13px;
  border-radius: 19px;
  background-color: rgb(255 255 255);
  border: 1px solid #e4e8ee;
}

.react-datepicker__navigation-icon--next {
  left: 2px;
}

.react-datepicker__navigation-icon--previous {
  right: 2px;
}

.react-datepicker__navigation--previous {
  top: 16px !important;
  left: 13px;
  border-radius: 19px;
  background-color: rgb(255 255 255);
  border: 1px solid #e4e8ee;
}

.react-datepicker__navigation-icon::before {
  border-color: #040404;
  border: 0px solid;
  border-width: 1px 1px 0px 0px;
  content: "";
  display: block;
  height: 7px;
  position: absolute;
  top: 9px;
  width: 7px;
}

.react-datepicker__day-names {
  margin-top: 0px !important;
  display: flex;
  justify-content: space-between;
}
.react-datepicker__month-names {
  margin-top: 0px !important;
  display: flex;
  justify-content: space-between;
}
.react-datepicker__year-names {
  margin-top: 0px !important;
  display: flex;
  justify-content: space-between;
}
.react-datepicker__year-wrapper{
max-width: 232px !important;
max-height: 244px !important;
overflow: auto;
  }
.react-datepicker__month-wrapper{
max-width: 232px !important;
max-height: 244px !important;
overflow: auto;
  }
  .react-datepicker__month-container{
width: 250px  !important;
padding: 12px;
  }
.react-datepicker__current-month {
  font-size: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'NotoSans-Bold';
}

.react-datepicker-popper.example-datepicker-class {
  position: absolute;
  height: 232px;
  width: 244px;
  z-index: 999999;
}
.react-datepicker{
  z-index: 1;
}
.react-datepicker__month {
  height: 177px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0px;
}
.react-datepicker__year {
  height: 168px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0px;

}

.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

