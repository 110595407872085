
.pac-container{
    z-index: 9999;
    box-shadow: 0px 8px 23px #0000001A;
    border-radius: 16px;
    top: 20px;
    margin-top: 23px;
    margin-left: -25px;
    padding: 0px 16px 16px 16px
}

.pac-item{
    padding:5px;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-SemiBold';
}

[dir="rtl"] .pac-item {
  padding:5px;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'NotoSans-SemiBold';
  direction: rtl;
  text-align: right;
}

[dir ="rtl"] .pac-container {
  z-index: 9999;
  box-shadow: 0px 8px 23px #0000001A;
  border-radius: 4px;
  top:20px;
  margin-top: 20px;
  margin-left: -20px;
  text-align: right;
}

.pac-container:after{
    background-image: none !important;
    height: 0px;
}

.input_placeholder_color:focus::-webkit-input-placeholder {
    color: #E0E0E0;
  }
  /* Mozilla Firefox 4 to 18 */
  .input_placeholder_color:focus:-moz-placeholder {
    color: #E0E0E0;
  }
  /* Mozilla Firefox 19+ */
  .input_placeholder_color:focus::-moz-placeholder {
    color: #E0E0E0;
  }
  /* Internet Explorer 10+ */
  .input_placeholder_color:focus:-ms-input-placeholder {
    color: #E0E0E0;
  }
  
  .input_placeholder_color::placeholder{
    color: #6F6F6F;
    font-size: 0.75rem;
  }

  [dir ="rtl"] .pac-container.pac-logo.hdpi {
  width: 252px !important;
  left: 1106px !important;
  top: 401px !important;
  }